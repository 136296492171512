import '@/styles/main.scss'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { Scroll, Touch } from 'vuetify/directives'
import { ru } from 'vuetify/locale'

export default defineNuxtPlugin({
  name: 'vuetify',
  parallel: true,
  setup(app) {
    const vuetify = createVuetify({
      icons: {
        defaultSet: 'mdi',
        aliases: {
          ratingEmpty: aliases['ratingEmpty'],
          ratingFull: aliases['ratingFull'],
          ratingHalf: aliases['ratingHalf'],
        },
        sets: {
          mdi,
        },
      },
      theme: false,
      defaults: {
        global: {
          ripple: false,
          elevation: 0,
          VBtn: {
            style: 'text-transform: none;',
          },
        },
      },
      directives: {
        Touch,
        Scroll,
      },
      goTo: {
        options: {
          duration: 300,
          layout: false,
          offset: 0,
          easing: 'easeInOutCubic',
        },
      },
      locale: {
        locale: {
          ru: ru,
        },
      },
    })
    app.vueApp.use(vuetify)
  },
})
