<template>
  <div
    class="p-input d-flex"
    :class="{ err: errState, valid: validState }"
  >
    <div class="p-input__mask title-5 no-pointer-events">
      {{ mask }}
    </div>
    <div
      class="p-input__pre no-pointer-events title-5 blue--bg d-flex justify-center align-center black--text"
    >
      +7
    </div>
    <input
      ref="inputRef"
      v-model="modelInput"
      autocomplete="tel"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      class="title-5"
      type="tel"
      :tabindex="tabindex"
      @keydown.enter="prevent"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    >
  </div>
</template>

<script setup lang="ts">
import { validPhone } from '~/helpers/validators'

const props = defineProps({
  value: {},
  tabindex: {
    type: String,
  },
})
const emit = defineEmits(['update:value', 'validate', 'submit'])
const modelInput = ref('')
const inputRef = ref()
const errState = ref(false)
const validState = ref(false)

const mask = computed(() => {
  const str = '(___) ___-__-__'.slice(modelInput.value.length, 15)
  return modelInput.value + str
})

const prevent = (e) => {
  e.preventDefault()
  emit('submit')
}
const onInput = () => {
  if (modelInput.value === '(') return '('
  let cleaned = modelInput.value
    ? String(modelInput.value).replace(/\D/g, '')
    : ''
  if (cleaned.startsWith('89')) {
    cleaned = cleaned.replace(/89/, '9')
  }
  let formatted
  if (cleaned.length < 2) {
    formatted = cleaned.replace(/(\d)/, '($1')
  }
  else if (cleaned.length < 4) {
    formatted = cleaned.replace(/(\d{0,3})/, '($1')
  }
  else if (cleaned.length < 7) {
    formatted = cleaned.replace(/(\d{3})(\d{0,3})/, '($1) $2')
  }
  else if (cleaned.length < 9) {
    formatted = cleaned.replace(/(\d{3})(\d{3})(\d{0,2})/, '($1) $2-$3')
  }
  else if (cleaned.length < 11) {
    formatted = cleaned.replace(
      /(\d{3})(\d{3})(\d{2})(\d{0,2})/,
      '($1) $2-$3-$4',
    )
  }
  else {
    if (cleaned.startsWith('7') || cleaned.startsWith('8')) {
      formatted = cleaned.replace(
        /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
        '($2) $3-$4-$5',
      )
    }
    else {
      formatted = cleaned.replace(
        /(\d{3})(\d{3})(\d{2})(\d{2})(.*)/,
        '($1) $2-$3-$4',
      )
    }
  }
  modelInput.value = formatted
  emit('update:value', formatted)
  const valid = validPhone('+7' + modelInput.value)
  errState.value = false
  emit('validate', valid)
}

const onBlur = () => {
  if (modelInput.value === '(') {
    modelInput.value = ''
    emit('update:value', '')
  }
  nextTick(() => {
    const valid = validPhone('+7' + modelInput.value)
    emit('validate', valid)
    errState.value = !valid
    validState.value = valid
  })
}
const onFocus = () => {
  if (!modelInput.value) {
    const start = '('
    modelInput.value = start
    emit('update:value', start)
  }
}

watch(
  () => props.value,
  () => {
    modelInput.value = props.value
    onInput()
  },
)

onMounted(() => {
  modelInput.value = props.value
  onInput()
})

defineExpose({ inputRef, onBlur })
</script>

<style scoped lang="scss">
.p-input {
  position: relative;
  border-radius: 28px;
  background: color(white);
  input {
    height: 54px;
    border-radius: 28px;
    padding-left: 58px;
    border: 1px solid color(gray-2);
    width: 100%;
    transition: border-color 0.24s;
    position: relative;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }

    &::placeholder {
      color: color(gray-inactive);
    }

    &:focus {
      border-color: color(secondary-dark);
    }
  }

  &__pre {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    width: 54px;
    border-radius: 50px 0 0 50px;
    z-index: 1;
  }
  &__mask {
    position: absolute;
    left: 59px;
    top: calc(50% - 15px);
    line-height: 30px;
    @media (max-width: getBreakpoint(tablet)) {
      top: calc(50% - 14px);
    }
  }

  &.valid {
    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 7px;
      right: 20px;
      top: calc(50% - 5px);
      border-left: 1.5px solid color(secondary-dark);
      border-bottom: 1.5px solid color(secondary-dark);
      transform: rotate(-45deg);
      z-index: 1;
    }
  }

  &.err {
    input {
      border-color: color(red);
    }
    &:after {
      content: "Неверный формат номера";
      position: absolute;
      color: color(red);
      font-size: 11px;
      top: calc(100%);
    }
  }
}
</style>
