import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { locationsApi } from '~/api'

export default defineNuxtPlugin({
  name: 'getCity',
  parallel: true,
  async setup() {
    const $route = useRoute()
    const cityStore = useCities()
    const mainStore = useMainStore()
    const tariffsStore = useTariffs()
    const needGetCity = computed(() => cityStore.getNeedGetCity)
    const currentCity = computed(() => cityStore.getCity)
    const requestParams = computed(() => cityStore.getRequestParams)
    const getCommonHeaders = computed(() => mainStore.getCommonHeaders)

    const checkCity = async () => {
      const queries = new URLSearchParams($route.fullPath.split('?')[1])
      for (const query of queries.entries()) {
        if (query[0] === 'yandex_geo')
          requestParams.value.yandexGeo = +query[1] || undefined
        if (query[0] === 'google_geo')
          requestParams.value.googleGeo = +query[1] || undefined
      }
      requestParams.value.clientIp = undefined
      let responce
      try {
        responce = (
          await locationsApi.getAncestorsChainByGeo(
            {
              withAvailableSmartFilters: false,
              morph: true,
              siteId: process.env.VITE_SITE_ID,
              ...requestParams.value,
            },
            {
              headers: {
                common: getCommonHeaders.value,
              },
            },
          )
        ).data
      }
      catch { /* empty */ }

      const { city } = cityStore.getAddressObjectsFromResponse(responce)
      cityStore.$patch({
        certainty: responce?.certainty,
        currentCity: city,
      })
      tariffsStore.$patch({
        addressAncestors: responce,
      })
    }

    if (needGetCity.value || !currentCity.value?.fias_id) {
      await checkCity()
    }
  },
})
