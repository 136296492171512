<template>
  <div
    ref="parent"
    class="d-flex modal-support white--bg"
    :style="{
      '--mainColor': color && color.main,
      '--font': color.font,
      '--font2': color.font2,
      '--font3': color.font3,
      '--consult': color.consult,
      '--price': color.price,
    }"
  >
    <div
      class="modal-support__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close.svg"
        alt="close"
      >
    </div>
    <div class="d-flex flex-column flex-grow">
      <template v-if="segmentationActive">
        <p class="modal-support__old-title headline-6">
          Вы являетесь действующим абонентом {{ partner.provider.name }}
        </p>
        <p class="modal-support__old-subtitle title-2 gray-dark--text">
          Мы не сможем ответить на вопросы по действующему подключению или
          сменить ваш текущий тариф
        </p>
        <p class="title-5">
          Рекомендуем позвонить по номеру
        </p>
        <a
          v-if="phone"
          class="modal-support__old-phone secondary-dark--text headline-3"
          :href="'tel:' + phone.call_number"
          @click.prevent="callClick(phone.call_number, 'SHOW_SUPPORT')"
        >{{ phone.display_number }}</a>
        <span class="subhead-3 d-block gray-dark--text">Звонок бесплатный по РФ</span>
        <p
          v-if="partner?.provider?.lk_url"
          class="modal-support__old-lk title-5"
        >
          или узнать информацию в
          <a
            class="secondary-dark--text"
            :href="partner.provider.lk_url"
            target="_blank"
          >личном кабинете</a>
        </p>
      </template>
      <template v-else>
        <p class="modal-support__title headline-2">
          <template v-if="title">
            {{ title }}
          </template>
          <template v-else-if="showNow">
            Мы перезвоним вам
            <span class="d-block">за
              <span :style="`color: ${color && color.main};`">60</span>
              секунд</span>
          </template>
          <template v-else>
            Мы перезвони вам в удобное время
          </template>
        </p>

        <div class="modal-support__switch d-flex">
          <div class="modal-support__switch-wrap">
            <button
              class="modal-support__switch-btn title-5 text-no-wrap"
              :class="{
                active: tab === 'new',
              }"
              @click="
                tab = 'new';
                changeTariffType = '';
              "
            >
              Подключение
            </button>
            <button
              class="modal-support__switch-btn title-5 text-no-wrap"
              :class="{
                active: tab === 'help',
              }"
              @click="
                tab = 'help';
                changeTariffType = '';
              "
            >
              Поддержка
            </button>
            <button
              class="modal-support__switch-btn title-5 text-no-wrap"
              :class="{
                active: tab === 'other',
              }"
              @click="
                tab = 'other';
                changeTariffType = '';
              "
            >
              Другое
            </button>
          </div>
        </div>
        <template v-if="tab === 'new' || changeTariffType === 'add'">
          <form
            action="#"
            autocomplete="off"
            @submit.prevent="submit"
          >
            <span class="modal-support__label black--text title-5">Введите телефон</span>
            <VPhoneInput
              ref="dPhone"
              v-model:value="phoneNumber"
              class="w-100 q-modal__section"
              tabindex="1"
              @validate="checkPhoneValidation($event)"
            />
            <LazyVMenu
              content-class="white--bg"
              @update:model-value="showActiveSortList"
            >
              <template #activator="{ props }">
                <span
                  v-bind="props"
                  class="modal-support__menu pointer d-flex align-center gray-dark--text title-5"
                >
                  Перезвоним {{ timeToCall.replace("15 минут", title ? '15 минут' : "60 секунд") }}
                  <img
                    :class="{ rotate: props['aria-expanded'] === 'true' }"
                    style="transition: transform 0.24s; width: 20px; height: 20px;"
                    loading="lazy"
                    src="@/assets/images/svg/arrow-expand.svg"
                    alt="arrow"
                  >
                </span>
              </template>
              <LazyVList elevation="4">
                <LazyVListItem
                  :value="timeNow"
                  :disabled="timeNow.text === timeToCall"
                  @click="setTimeToCall(timeNow)"
                >
                  <span class="subhead-3">{{ timeNow.text.replace("15 минут", title ? '15 минут' : "60 секунд") }}</span>
                </LazyVListItem>
                <LazyVListItem
                  v-for="item in timeList"
                  :key="item.val"
                  :disabled="item.text === timeToCall"
                  @click="setTimeToCall(item)"
                >
                  <span class="subhead-3">{{ item.text }}</span>
                </LazyVListItem>
              </LazyVList>
            </LazyVMenu>
            <input
              class="modal-support__fakesubmit"
              type="submit"
            >

            <d-btn
              :loading="loading"
              :disabled="!isValidPhone"
              class="w-100 modal-support__submit"
              color="primary"
              size="medium"
              @click.prevent="submit"
            >
              Жду звонка
            </d-btn>
          </form>

          <p class="subhead-6 gray-dark--text">
            Отправляя, вы соглашаетесь с
            <button
              class="secondary-dark--text"
              @click="goAway('/legal/privacy/')"
            >
              политикой обработки данных
            </button>
          </p>
        </template>
        <div
          v-else-if="tab === 'help'"
          class="modal-support__old"
        >
          <div class="modal-support__ch-type d-flex flex-column">
            <p
              class="modal-support__chbx"
              :class="{ active: chType === 'change1' }"
              @click="chType = 'change1'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change1' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Оплата услуг
            </p>
            <p
              style="margin-top: 8px"
              class="modal-support__chbx"
              :class="{ active: chType === 'change2' }"
              @click="chType = 'change2'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change2' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Оборудование
            </p>
            <p
              style="margin-top: 8px"
              class="modal-support__chbx"
              :class="{ active: chType === 'change3' }"
              @click="chType = 'change3'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change3' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Не работает интернет/ТВ
            </p>
          </div>
          <d-btn
            :disabled="!['change1', 'change2', 'change3'].includes(chType)"
            class="w-100 modal-support__submit"
            color="primary"
            size="medium"
            style="margin-top: 16px; margin-bottom: 0"
            @click="changeType"
          >
            Далее
          </d-btn>
        </div>
        <div
          v-else
          class="modal-support__old"
        >
          <div class="modal-support__ch-type d-flex flex-column">
            <p
              class="modal-support__chbx"
              :class="{ active: chType === 'change4' }"
              @click="chType = 'change4'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change4' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Смена тарифа (я абонент {{ partner.provider.name }})
            </p>
            <p
              style="margin-top: 8px"
              class="modal-support__chbx"
              :class="{ active: chType === 'add' }"
              @click="chType = 'add'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'add' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Подключение дополнительных услуг
            </p>
            <p
              style="margin-top: 8px"
              class="modal-support__chbx"
              :class="{ active: chType === 'change5' }"
              @click="chType = 'change5'"
            >
              <span
                class="white--text d-flex"
                :class="chType === 'change5' ? 'primary--bg' : 'white--bg'"
              >
                <span
                  class="mask-icon mask-size-lg mask-ischecked"
                /></span>Другое
            </p>
          </div>
          <d-btn
            :disabled="!['change4', 'change5', 'add'].includes(chType)"
            class="w-100 modal-support__submit"
            color="primary"
            size="medium"
            style="margin-top: 16px; margin-bottom: 0"
            @click="changeType"
          >
            Далее
          </d-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { createInteraction } from '~/composible/createInteraction'

import { goAway } from '~/helpers'
import { callTimeComposible } from '~/composible/callTimeComposible'

const props = defineProps({
  title: {},
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const parent = ref()
const phoneNumber = ref('')
const chType = ref('')
const changeTariffType = ref('')
const tab = ref('new')
const phone = computed(() => mainStore.getCallNumber?.support)
const showNow = computed(() => mainStore.getCallSlots?.display_right_away)
const loading = ref(false)
const isValidPhone = ref(false)
const dPhone = ref()
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const partner = computed(() => mainStore.getDomainConfig)
const segmentation = computed(() => mainStore.getSegmentation)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const color = computed(() => {
  if (partner.value?.provider.config?.color)
    return partner.value?.provider.config?.color
})
const $router = useRouter()
const tariffsStore = useTariffs()
const houseUrl = computed(() => tariffsStore.getHouseUrl)

const {
  timeList,
  timeNow,
  timeToCall,
  callTime,
  setTimeToCall,
  showActiveSortList,
} = callTimeComposible()

const changeType = () => {
  changeTariffType.value = chType.value
  if (chType.value?.startsWith('change')) {
    if (!interactionsSended.value) {
      mainStore.$patch({
        interactionsSended: true,
      })
      const tariffS = {}
      cI(
        true,
        undefined,
        tariffS,
        { request_type: 'SHOW_SUPPORT' },
        undefined,
        undefined,
      )
    }
    mainStore.$patch({
      oldClient: segmentation.value,
    })
    oldClientCookie.value = segmentation.value
  }
  else {
    nextTick(() => {
      if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
    })
  }
}

const { cI } = createInteraction()

const submit = async () => {
  if (!isValidPhone.value) {
    dPhone.value.onBlur()
    return
  }
  loading.value = true

  const tariffS = {}
  const lead = {
    phone: '+7' + phoneNumber.value.replace(/[\-(\s)]/g, ''),
    call_time: callTime.value,
  }

  const meta = {
    request_name: callTime.value ? 'support-modal' : props.title ? 'tariff-consult' : '60sec-modal',
  }

  const ciResp = await cI(
    callTime.value ? !!houseUrl.value : true,
    lead,
    tariffS,
    meta,
    undefined,
  )

  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'form',
    action: 'success',
    label: 'support-modal',
  })

  tariffsStore.$patch({
    orderId: callTime.value ? ciResp : undefined,
    interactionId: callTime.value ? ciResp : undefined,
    timeToCall: callTime.value
      ? timeToCall.value
      : 'Специалист уже звонит вам!',
  })
  loading.value = false

  nextTick(() => {
    $router.push({ path: '/complete/' })
  })
}

const checkPhoneValidation = (event) => {
  isValidPhone.value = event
}

const callClick = (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    mainStore.$patch({
      interactionsSended: true,
    })
    const tariffS = {}
    cI(true, undefined, tariffS, { request_type: type }, undefined, undefined)
  }
}
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onBeforeMount(async () => {
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'form',
    action: 'open',
    label: 'support-modal',
  })
  await mainStore.fetchCallSlots()
  timeToCall.value = timeNow.value.text
})

onMounted(() => {
  if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
watch(
  () => tab.value,
  () => {
    if (tab.value === 'new') {
      nextTick(() => {
        if (dPhone.value?.inputRef) nextTick(() => dPhone.value.inputRef.focus())
      })
    }
  },
)
</script>

<style scoped lang="scss">
.modal-support {
  width: 500px;
  padding: 40px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 16px 16px 0 0;
    padding: 24px 16px 12px;
    height: 100%;
    flex-wrap: wrap;
    width: 100vw;
    min-height: 50vh;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 24px 16px;
  }

  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &:deep(.p-input), &:deep(.t-input) {
    background-color: color(gray-p);
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-darker);
    z-index: 1;
    img {
      opacity: .7;
      transition: opacity .24s;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &__menu {
    margin-right: auto;
    margin-bottom: 20px;
  }
  &__title {
    margin-bottom: 24px;
    padding-right: 24px;
  }

  &__switch {
    margin-right: -16px;
    &-wrap {
      padding: 3px;
      border-radius: 28px;
      border: 1px solid color(gray-darker);
      background: color(gray-p);
      margin-right: auto;
      margin-bottom: 24px;
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 16px;
      }
    }

    &-btn {
      padding: 8px 15px;
      border: 1px solid transparent;
      border-radius: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding: 8px 11px;
      }
      &.active {
        background: color(white);
        border: 1px solid var(--mainColor);
      }
    }
  }

  &__old {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 8px;
      max-width: 350px;
    }
    &-subtitle {
      margin-bottom: 16px;
    }
    &-phone {
      margin-top: 8px;
      transition: color 0.24s;
      &:hover {
        color: var(--mainColor);
      }
    }
    &-lk {
      margin-top: 16px;
      a {
        transition: color 0.24s;
        &:hover {
          color: var(--mainColor);
        }
      }
    }
  }

  &__chbx {
    flex-shrink: 0;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
    color: color(black);
    border: 1px solid color(gray-darker);
    border-radius: 12px;
    padding: 16px 16px 16px 48px;
    & > span {
      position: absolute;
      border-radius: 50%;
      left: 16px;
    }

    &.active {
      & > span {
        background: var(--mainColor);
        color: var(--font2);
      }
    }
    &:not(.active) {
      & > span {
        border: 1px solid color(gray-darker);
      }
    }
  }

  &__fakesubmit {
    max-height: 0 !important;
    overflow: hidden !important;
    opacity: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
  }

  &__submit {
    margin-bottom: 24px;
    height: 56px;
    box-shadow: none !important;
    &:not(:disabled) {
      background: var(--mainColor) !important;
      color: var(--font2) !important;
      &:hover {
        background: var(--mainColor) !important;
      }
    }
    &:focus,
    &:active {
      background: var(--mainColor) !important;
    }
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    span {
      margin-right: auto;
    }
  }
}
</style>
