import { contentApi } from '~/api'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

export default async function getTextAsync(input?: any) {
  const cityStore = useCities()
  const currentCity = computed(() => cityStore.getCity)
  const mainStore = useMainStore()
  const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
  const provider = computed(() => mainStore.getDomainConfig)
  const res = (
    await contentApi.getProviderPage(
      {
        providerId: provider.value?.provider?.id || 0,
        fiasId: currentCity.value?.fias_id,
        domain: provider.value?.domain || 'dominternet.ru',
        ...input,
      },
      {
        headers: {
          common: getCommonHeaders.value,
        },
      },
    )
  ).data

  return res
}
