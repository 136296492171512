import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) {
    const store = useCities()
    await store.nuxtServerInit(to)
    const redirectObj = computed(() => store.getRedirectObj)
    if (redirectObj.value) {
      return navigateTo(redirectObj.value.url, { redirectCode: redirectObj.value.code || undefined })
    }
  }
  if (import.meta.client) {
    const nuxtApp = useNuxtApp()
    const mainStore = useMainStore()
    const newHeaders: any = {}
    const exclude = ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip']
    Object.entries(mainStore.commonHeaders).filter(el => !exclude.includes(el[0])).forEach(el => newHeaders[el[0]] = el[1])
    mainStore.commonHeaders = newHeaders
    const diNotProviderCookie = useCookie('diNotProvider')
    if (
      !(
        /providers-provider.*/i.test(to.name)
        || (/city-reviews.*/i.test(to.name)
          && /\/providers\//i.test(to.path)
          && to.name !== 'city-providers-provider-tariffs-id')
      )
    ) {
      nuxtApp.hook('page:finish', () => {
        mainStore.$patch({ diProvider: false })
        diNotProviderCookie.value = true
      })
    }
  }
})
